@import url(
"https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
);
.login-img img {
    width: 100%;
    max-width: 400px;
    margin: auto;
    display: block;
}

.price-charge {
    font-size: xxx-large;
    font-weight: bolder;
    margin: 0;
    padding: 0;
    text-align: center;
}

.pricing-plan-card {
    color: white;
    margin: 0;
    font-size: small;
}

.ant-transfer-operation .ant-btn-sm {
    height: auto;
}
